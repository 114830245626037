'use client';

import { useEffect, useState } from 'react';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    OneTrust: any;
    OnetrustActiveGroups: string;
  }
}

const UpdateDYConsentScript = () => {
  const [isOneTrustLoaded, setIsOneTrustLoaded] = useState(false);
  const [isDYLoaded, setIsDYLoaded] = useState(false);

  useEffect(() => {
    let oneTrustIntervalId: ReturnType<typeof setTimeout>;
    let dyIntervalId: ReturnType<typeof setTimeout>;
    const checkIfOneTrustIsLoaded = () => {
      if ((window as any).OneTrust) {
        setIsOneTrustLoaded(true);
        clearInterval(oneTrustIntervalId);
      }
    };

    const checkIfDYIsLoaded = () => {
      if ((window as any).DY && (window as any).DYO) {
        setIsDYLoaded(true);
        clearInterval(dyIntervalId);
      }
    };

    dyIntervalId = setInterval(checkIfDYIsLoaded, 250);
    oneTrustIntervalId = setInterval(checkIfOneTrustIsLoaded, 250);

    return () => {
      clearInterval(oneTrustIntervalId); // Cleanup on unmount
      clearInterval(dyIntervalId); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    if (isDYLoaded === true && isOneTrustLoaded === true) {
      window.OneTrust.OnConsentChanged(() => {
        updateDyActiveConsent();
      });
    }
  }, [isOneTrustLoaded, isDYLoaded]);
  return null;
};

const updateDyActiveConsent = () => {
  const oneTrustActiveGroups = window.OnetrustActiveGroups || '';
  const accepted = oneTrustActiveGroups.indexOf('C0004') !== -1;

  window.DY = window.DY || {};
  window.DY.userActiveConsent = { accepted: accepted };
  if (window.DYO?.ActiveConsent?.updateConsentAcceptedStatus) {
    window.DYO.ActiveConsent.updateConsentAcceptedStatus(accepted);
  }

  window.localStorage.setItem('userActiveConsent', accepted.toString());

  if (
    !accepted ||
    window.localStorage.getItem('userActiveConsent') == 'false'
  ) {
    const dyid_server = document.cookie
      .split('; ')
      .find((row) => row.startsWith('_dyid_server='))
      ?.split('=')[1];

    if (dyid_server) {
      localStorage.setItem('dyid_server', dyid_server);
    }

    document.cookie = '_dyid=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    document.cookie =
      '_dyid_server=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
};

export default UpdateDYConsentScript;
