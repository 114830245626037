'use client';

import Link from '@next-app/components/elements/Link/Link';
import Button from '../elements/Button/Button';
import Input from '../elements/Input/Input';
import Checkbox from '../elements/Checkbox/Checkbox';
import Heading from '../elements/Heading/Heading';
import { signInModalNames } from '@next-app/config/elementNameConfig';
import constants from '@next-app/constants/constants';
import { loginUser } from '../../utils/authenticationUser';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '@next-app/lib/store';
import classes from './sign-in.module.scss';
import Modal from '../elements/Modal/Modal';
import parse from 'html-react-parser';
import { decodeHTMLEntities } from '../../utils/decodeHTMLEntities';
import { LoginAccountDTMEvent } from '@next-app/utils/dtm-event';
import { FormException } from '@next-app/interface/Common';
import { dyEvent } from '@next-app/utils/dy-event';
import Conditional from '../Conditional';
import { useSearchParams, useRouter } from 'next/navigation';
import { DynamicDTMEvent } from '@next-app/utils/dtm-event';
import dynamic from 'next/dynamic';

const CreateAccountModal = dynamic(
  () => import('@next-app/components/CreateAccountModal/CreateAccountModal'),
  { ssr: false },
);

const ForgotModal = dynamic(
  () => import('@next-app/components/ForgotModal/ForgotModal'),
  { ssr: false },
);

const SignInModal = () => {
  const router = useRouter();
  const ref = useRef<string | Element>('');

  const searchParams = useSearchParams();
  const login = searchParams.get('login') || false;
  const dest_url = searchParams.get('dest_url') || '';

  const dispatch = useDispatch();
  const initial = useSelector((state: any) => state.init);
  const { sessionInfo, init } = initial;

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPopOverContent, setShowPopOverContent] = useState(false);
  const [error, setErrors] = useState({
    emailError: '',
    passwordError: '',
    formError: '',
    otherError: '',
  });
  const [isstaySignedIn, setIsStaySignedIn] = useState(false);
  const [showCreateAccModal, setShowCreateAccModal] = useState(false);
  const [showForgetModal, setShowForgetModal] = useState(false);

  useEffect(() => {
    const modalElement = document?.getElementById('signInModal') as HTMLElement;
    if (login && dest_url) {
      if (modalElement) {
        const modalInstance = new window.bootstrap.Modal(modalElement);
        modalInstance.show();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  if (!Object.keys(sessionInfo).length) {
    return;
  }
  const {
    featureToggles,
    dynSess: { dynSessConfNumber },
    profile: { loginStatus = '' } = {},
  } = sessionInfo.sessionInfo;
  const { rewardsEnabled } = featureToggles;
  const { privacyPolicy } = constants.links;

  const handleStaySignedIn = (checked: boolean) => {
    setIsStaySignedIn(checked);
  };

  const handleValidation = (formExceptions: any) => {
    const isEmailError = formExceptions.find(
      (el: any) => el.propertyPath === 'email',
    );

    const isPasswordError = formExceptions.find(
      (el: any) => el.propertyPath === 'password',
    );

    const isError = formExceptions.find((el: any) => el.propertyPath === '');

    if (isEmailError) {
      setErrors((prev) => ({
        ...prev,
        emailError: isEmailError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        emailError: '',
      }));
    }

    if (isPasswordError) {
      setErrors((prev) => ({
        ...prev,
        passwordError: decodeHTMLEntities(isPasswordError.localizedMessage),
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        passwordError: '',
      }));
    }

    if (isError) {
      setErrors((prev) => ({
        ...prev,
        formError: isError.localizedMessage,
      }));
    }
  };

  const showPopOverTooltip = () => {
    setShowPopOverContent(!showPopOverContent);
  };

  const closePopOverTooltip = () => {
    setShowPopOverContent(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const authIntegrationInfoUrl: string = `${init?.initResponse?.authIntegrationInfo?.baseURL}token`;
    const loggedInResponse: any = await loginUser(
      email,
      password,
      isstaySignedIn,
      dynSessConfNumber,
      authIntegrationInfoUrl,
    );

    const {
      formError = false,
      b2bRedirect,
      formExceptions,
    } = loggedInResponse || {};

    if (
      formError &&
      b2bRedirect &&
      formExceptions.some(
        (e: FormException) => e.propertyPath === 'REDIRECT_WARINING',
      )
    ) {
      window.location.href = b2bRedirect;
      return;
    }

    if (formError) {
      const { formExceptions } = loggedInResponse;
      await handleValidation(formExceptions);
      setLoading(false);
    } else {
      dispatch(
        setData({
          ...initial,
          sessionInfo: loggedInResponse,
        }),
      );

      // Refresh the current route to show the correct view GSA vs NON GSA
      if (dest_url) {
        window.location.href = dest_url;
      } else {
        if (loginStatus === 'guest' && window.location.pathname !== '/') {
          router.push('');
        }
        router.refresh();
      }

      await setErrors({
        emailError: '',
        passwordError: '',
        formError: '',
        otherError: '',
      });

      const { sessionInfo: info } = loggedInResponse;
      const { profile } = info;
      const data = {
        staySignedIn: info?.staySignedIn,
        userShopFor: profile?.userShopFor,
        lowerEnv: init?.initResponse?.lowerEnv,
        encryptedOrderId: info?.cartInfo?.encryptedOrderId,
        md5Email: profile?.md5Email,
        email: profile?.email,
        isGsaUser: profile?.gsaUser,
        lastName: profile?.lastName,
        dateOfBirth: profile?.dateOfBirth,
        firstName: profile?.firstName,
        orderId: info?.cartInfo?.cartId,
      };

      const dyData = {
        eventName: 'Login',
        type: 'login-v1',
        email: profile?.email,
      };
      dyEvent(dyData);

      window.bootstrap.Modal.getInstance(ref.current)?.hide();
      document.body.classList.remove('stay-connected-open');
      sessionStorage.setItem('loginStatus', 'loggedIn');

      LoginAccountDTMEvent(data);
    }
    setLoading(false);
  };

  const signInModalBodyClass = rewardsEnabled
    ? classes['modalDialog']
    : classes['modalDialogWrapper'];

  const disableClass = loading ? `${classes['disable']}` : '';

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <Modal
        showHeader={true}
        id="signInModal"
        dialogClass={`modal-dialog ${signInModalBodyClass}`}
        contentClass={`modal-content ${classes['modal-content']}`}
        headingTagName="4"
        modalTitle="Sign In"
        closeModalOnClick={() => {
          setShowPopOverContent(false);
          setErrors({
            emailError: '',
            passwordError: '',
            formError: '',
            otherError: '',
          });
        }}
        wrapperClass={`${classes['modalWrapperSection']}`}
        closeButtonId="modalCloseButton"
        ref={ref}
      >
        {error?.formError && (
          <div style={{ color: 'red' }}>{error?.formError}</div>
        )}

        <Conditional if={error?.passwordError.includes('expired')}>
          <div className={`${classes['error-msg']}`}>
            {' '}
            {parse(signInModalNames.passwordExpiryErrorMessage)}{' '}
          </div>
        </Conditional>
        <form>
          <Input
            automation-id="login_email"
            type="email"
            labelText="Email"
            customClass={error.emailError ? classes.Inputerror : ''}
            datatestid="input-signin-email"
            id="input-signin-email"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {error?.emailError && (
            <div className={`${classes['error-msg']}`}>{error?.emailError}</div>
          )}
          <div className={`${classes['inputPassword']}`}>
            <Input
              automation-id="login_pass"
              type={showPassword ? 'text' : 'password'}
              labelText="Password"
              customClass={error.passwordError ? classes.Inputerror : ''}
              datatestid="input-signin-password"
              id="input-signin-password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <i
              className={
                showPassword
                  ? `${classes['password-icon']} ${classes['hideIcon']}`
                  : `${classes['password-icon']} ${classes['showIcon']}`
              }
              onClick={togglePasswordVisibility}
              role="button"
              aria-label="Show Password"
              data-testid="signin-password-eye"
            ></i>
          </div>
        </form>
        <Conditional
          if={
            !!error?.passwordError && !error.passwordError.includes('expired')
          }
        >
          <div className={`${classes['error-msg']}`}>
            {error?.passwordError}
          </div>
        </Conditional>
        {rewardsEnabled ? (
          <div>
            <div
              className={`${classes['checkboxWrapper']}  d-flex text-center justify-content-between`}
            >
              <div className=" d-flex text-center">
                <Checkbox
                  label="Keep me signed in"
                  checked={isstaySignedIn}
                  onChange={handleStaySignedIn}
                  ariaLabelledBy="checkbox-label"
                  name="checkbox"
                  ariaDescribedBy="checkbox-description"
                  datatestid="keep-signed-in-signup"
                />

                <span
                  data-toggle="popover"
                  className={`${classes['staySignPopOverText']}`}
                  onClick={showPopOverTooltip}
                  data-content="Popover content"
                  data-testid="popover-tool-tip"
                >
                  <span className={`ls-icon ${classes['icnHelpOtl']}`}></span>
                </span>

                {showPopOverContent && (
                  <div className={`${classes['popOverStaySigned']}`}>
                    <span
                      onClick={closePopOverTooltip}
                      className={`${classes['close-icon']}`}
                    >
                      {' '}
                    </span>
                    {signInModalNames.staySignIn}
                  </div>
                )}
              </div>
              <Link
                data-bs-target="#dynamicModalWrapper"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                className={`${classes['forgotPassLink']} ${classes['forgotPassLinkRewardsEnabled']}`}
                onClick={() => {
                  setShowForgetModal(true);
                }}
              >
                {signInModalNames.forgotPass}
              </Link>
            </div>
            <p> {signInModalNames.keepUnchecked} </p>
            <p>
              {signInModalNames.certifyAge}
              <Link href="/help/rewards-terms/">
                {signInModalNames.rewardTermCondition}
              </Link>{' '}
              {signInModalNames.andText}{' '}
              <Link href={privacyPolicy}>{signInModalNames.privacyPolicy}</Link>
              .
            </p>

            <Button
              onClick={handleSubmit}
              buttonSize="full"
              variant="primary"
              customClass={`${classes['signInModalButton']} ${disableClass}`}
              aria-label="Close"
              data-testid="submit-button-signin"
              automationId="login_button"
            >
              {signInModalNames.signIn}
            </Button>

            <p className={`text-center mb-0 mt-3 ${classes['signUpText']}`}>
              {' '}
              {signInModalNames.signedInReward}
            </p>

            <Link
              className={`d-flex justify-content-center text-center mb-3 ${classes['membershipText']}`}
              href="/rewards/?linkMembership=true"
            >
              {' '}
              {signInModalNames.linkMembership}
            </Link>
            <div className={`${classes['createAccountSection']}`}>
              <Heading customClass={`${classes['newUserAccount']}`} tagName="6">
                {signInModalNames.newUserAccount}
              </Heading>
              <Button
                data-bs-toggle="modal"
                data-bs-target="#dynamicModalWrapper"
                buttonSize="full"
                customClass={`${classes['signInModalButton']}`}
                variant="secondary"
                s_objectid="global"
                onClick={() => {
                  DynamicDTMEvent({ event: 'registerStart' });
                  setShowCreateAccModal(true);
                }}
              >
                {' '}
                {signInModalNames.createAccount}{' '}
              </Button>
              <span>{signInModalNames.trackOrder}</span>
            </div>
          </div>
        ) : (
          <div className={`${classes['signInBottomWrapper']}`}>
            <div className={`d-md-flex gap-3 order-md-2 ${disableClass}`}>
              <Button
                onClick={handleSubmit}
                buttonSize="full"
                variant="primary"
                aria-label="Close"
                customClass="order-md-2"
                data-testid="submit-button-signin"
                automationId="login_button"
              >
                {signInModalNames.signIn}
              </Button>
              <Button
                automation-id="login_cancel"
                variant="ternary"
                data-bs-dismiss="modal"
                customClass={`${classes['cancel-button']}`}
                aria-label="Close"
                data-testid="signin-close-button"
                onClick={() => {
                  setErrors({
                    emailError: '',
                    passwordError: '',
                    formError: '',
                    otherError: '',
                  });
                }}
              >
                Cancel
              </Button>
            </div>
            <Link
              automation-id="forget_password_link"
              data-bs-target="#dynamicModalWrapper"
              data-bs-toggle="modal"
              className={`${classes['forgotPassLink']}`}
              data-bs-dismiss="modal"
              onClick={() => {
                setShowForgetModal(true);
              }}
            >
              {signInModalNames.forgotPass}
            </Link>
          </div>
        )}
      </Modal>
      <Conditional if={showCreateAccModal}>
        <CreateAccountModal
          showCreateAccModal={showCreateAccModal}
          setShowCreateAccModal={setShowCreateAccModal}
        />
      </Conditional>
      <Conditional if={showForgetModal}>
        <ForgotModal setShowForgetModal={setShowForgetModal} />
      </Conditional>
    </>
  );
};

export default SignInModal;
